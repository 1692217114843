import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AgentsService } from '../../remote-api/api/agents-services';
import AgentsDetails from './agents.details.component';
import AgentsListComponent from './agents.list.component';

const agentsService = new AgentsService();
let csTableData$ = agentsService.getAgents();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Agents() {
  const history = useHistory();
  const query = useQuery();
  const [rows, setRows] = React.useState([]);
  const columns = [
    { field: 'code', headerName: 'Agent Code', width: 350 },
    { field: 'name', headerName: 'Agent Name', width: 350 },
    { field: 'type', headerName: 'Agent type', width: 350 },
    {
      field: 'contact',
      headerName: 'Contact Number',
      width: 300,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: params => {
        const onClickEdit = () => {
          const { id } = params.row;
          history.push(`/agents/management/${id}?mode=edit`);
        };

        const onClickDelete = () => {};

        return (
          <div>
            <CreateIcon style={{ cursor: 'pointer', color: '#626BDA' }} onClick={onClickEdit} />
            <DeleteIcon style={{ cursor: 'pointer', color: '#626BDA', marginLeft: '5px' }} onClick={onClickDelete} />
          </div>
        );
      },
      // valueGetter: (params) =>
      //   `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    },
  ];

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              code: ele.agentBasicDetails.code,
              name: ele.agentBasicDetails.name,
              type: ele.agentBasicDetails.type,
              contact: ele.agentBasicDetails.contactNos.length > 0 ? ele.agentBasicDetails.contactNos[0].contactNo : '',
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  // useObservable(csTableData$, setRows);

  return (
    <div>
      {query.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: 'inherit',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            Agent Management - Create Agent
          </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <AgentsListComponent rows={rows} columns={columns} />;
          case 'create':
            return <AgentsDetails />;
          default:
            history.push('/agents/management?mode=viewList');
        }
      })()}
    </div>
  );
}
