import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { CodeConfigurationService } from '../remote-api/api/code-generation-services';
import { EO2V2DragNDrop } from '../shared-components';
const configService = new CodeConfigurationService();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: 182,
  },
  prospectImportRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CodeGenerationComponent(props) {
  const classes = useStyles();
  const [fieldList, setFieldList] = React.useState([
    {
      entityId: 'item0',
      name: 'SEQUENCENO',
      value: '',
    },
  ]);

  const [snackText, setSnackText] = React.useState('');
  const [snackText1, setSnackText1] = React.useState('');
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnack1, setOpenSnack1] = React.useState(false);
  const [step, setStep] = React.useState('');
  const [configId, setConfigId] = React.useState('');
  // const [usedTypes, setUsedTypes] = React.useState(["SEQUENCENO"]);

  const [codeOptions, setCodeOptions] = React.useState([
    { id: 1, name: 'Policy Code', code: 'POLICYCODE' },
    { id: 1, name: 'Quotation Number', code: 'QUOTATIONNUMBER' },
    { id: 1, name: 'Invoice Number', code: 'INVOICENUMBER' },
    { id: 1, name: 'Receipt Number', code: 'RECEIPTNO' },
    { id: 1, name: 'Membership Number', code: 'MEMBERSHIPNO' },
  ]);
  const [codeTypes, setCodeTypes] = React.useState([
    { id: 1, name: 'Insurance Code', code: 'INSURANCECODE' },
    { id: 2, name: 'Corporate Code', code: 'CORPORATECODE' },
    { id: 3, name: 'Retail Code', code: 'RETAILCODE' },
    { id: 4, name: 'Plan Code', code: 'PLANCODE' },
    { id: 5, name: 'Year', code: 'YEAR' },
    { id: 6, name: 'Month', code: 'MONTH' },
    { id: 7, name: 'Agent Code', code: 'AGENTCODE' },
    { id: 8, name: 'Fixed Text', code: 'FIXEDTEST' },
    { id: 9, name: 'Sequence No', code: 'SEQUENCENO' },
    { id: 11, name: 'Anniversay', code: 'ANNIVERSARY' },
  ]);

  const [sequenceList, setSequenceList] = React.useState([
    { id: 1, value: 4 },
    { id: 2, value: 5 },
    { id: 3, value: 6 },
    { id: 4, value: 7 },
  ]);
  const [sequenceResetList, setSequenceResetList] = React.useState([
    { id: 1, value: 'YEARLY' },
    { id: 2, value: 'MONTHLY' },
    { id: 3, value: 'NONE' },
  ]);
  const [separatorList, setSeparatorList] = React.useState([
    { id: 1, name: '/', value: '/' },
    { id: 2, name: '-', value: '-' },
    { id: 3, name: 'NONE', value: 'NONE' },
  ]);
  const [caseList, setCaseList] = React.useState([
    { id: 1, name: 'All Capital', value: 'ALLCAPITAL' },
    { id: 2, name: 'All Small', value: 'ALLSMALL' },
  ]);
  const [state, setConfigState] = React.useState({
    clientType: '',
    configName: 'POLICYCODE',
    characterCase: '',
    separator: 'NONE',
    sequenceResetFrequency: 'NONE',
  });

  const populateConfig = config => {
    let arr = [];
    config.typeConfigs.forEach((el, i) => {
      el['entityId'] = 'item' + i.toString();
      arr.push(el.name);
    });
    if (arr.length > 0) {
      // setUsedTypes(arr);
    }
    setFieldList(config.typeConfigs);
    setConfigId(config.id);

    setConfigState({
      ...state,
      clientType: config.clientName,
      configName: config.configName,
      characterCase: config.characterCase,
      separator: config.separator === null || config.separator === '' ? 'NONE' : config.separator,
      sequenceResetFrequency:
        config.sequenceResetFrequency === null || config.sequenceResetFrequency === ''
          ? 'NONE'
          : config.sequenceResetFrequency,
    });
  };
  const populateDefault = (config, cType) => {
    setFieldList([
      {
        entityId: 'item0',
        name: 'SEQUENCENO',
        value: '',
      },
    ]);
    setConfigId('');
    let clientType = state.clientType;
    let configName = state.configName;

    setConfigState({
      ...state,
      characterCase: '',
      separator: 'NONE',
      sequenceResetFrequency: 'NONE',
      clientType: cType,
      configName: config,
    });

    // setUsedTypes(["SEQUENCENO"])
  };

  const populateDefault1 = () => {
    setFieldList([
      {
        entityId: 'item0',
        name: 'SEQUENCENO',
        value: '',
      },
    ]);
    setConfigId('');
    let clientType = state.clientType;
    let configName = state.configName;

    setConfigState({
      ...state,
      characterCase: '',
      separator: 'NONE',
      sequenceResetFrequency: 'NONE',
      clientType,
      configName,
    });

    // setUsedTypes(["SEQUENCENO"])
  };

  const checkDisabled = e => {};

  const handleChange = e => {
    const { name, value } = e.target;
    setConfigState({
      ...state,
      [name]: value,
    });
    if (name === 'clientType') {
      configService.getConfigDetails(state.configName, value).subscribe(el => {
        if (el.step) {
          setStep(el.step);
          populateConfig(el);
        }
        if (!el.step) {
          setStep('FIRST');
          populateDefault(state.configName, value);
        }
      });
    }
    if (name === 'configName') {
      configService.getConfigDetails(value, state.clientType).subscribe(el => {
        if (el.step) {
          setStep(el.step);
          populateConfig(el);
        }
        if (!el.step) {
          setStep('FIRST');
          populateDefault(value, state.clientType);
        }
      });
    }
  };

  const addNewField = e => {
    let field = {
      entityId: 'item' + fieldList.length,
      name: '',
      value: '',
    };

    setFieldList([...fieldList, field]);
  };

  const handleField = (e, index) => {
    const { name, value } = e.target;
    const list = [...fieldList];
    // const list2 = [...usedTypes];
    // if (list2.indexOf(value) === -1) {
    //Not in the array, the value is pushed to the index assigned for that transaction row, so that if he changes again, it will overwrite the value in that position
    // list2[index] = value;
    // setUsedTypes(list2);
    // }
    list[index][name] = value;
    setFieldList(list);
  };

  const handleRemoveField = index => {
    let list = [...fieldList];
    // const list2 = [...usedTypes];
    // list2.splice(index, 1);
    // setUsedTypes(list2);
    list.splice(index, 1);
    setFieldList(list);
  };

  const saveConfigurations = condition => {
    if (
      state.clientType === '' ||
      state.clientType === null ||
      state.characterCase === '' ||
      state.characterCase === null ||
      state.separator === null ||
      state.sequenceResetFrequency === null
    ) {
      setSnackText1('Please fill up all fields marked with *');
      setOpenSnack1(true);
      return;
    }

    fieldList.forEach(x => {
      if (x.name === 'RETAILCODE' && (x.value === null || x.value === '')) {
        setSnackText1('Please provide retail code *');
        setOpenSnack1(true);
        return;
      }
      if (x.name === 'FIXEDTEST' && (x.value === null || x.value === '')) {
        setSnackText1('Please provide fixed text *');
        setOpenSnack1(true);
        return;
      }
      if (x.name === 'SEQUENCENO' && (x.value === null || x.value === '')) {
        setSnackText1('Please provide sequence number *');
        setOpenSnack1(true);
        return;
      }
    });

    fieldList.forEach((val, index) => {
      val['order'] = index + 1;
    });
    let payload = {
      configName: state.configName,
      clientName: state.clientType,
      characterCase: state.characterCase,
      separator: state.separator === 'NONE' ? '' : state.separator,
      sequenceResetFrequency: state.sequenceResetFrequency,
      typeConfigs: fieldList,
    };
    if (step === 'FIRST') {
      configService.saveCodeConfigurations(payload, condition).subscribe(res => {
        setSnackText('Successfully saved');
        setOpenSnack(true);
        if (condition === 'SUBMITTED') {
          getConfigInfo();
        }
      });
    }
    if (step === 'DRAFT') {
      configService.saveasDraft(payload, condition, configId).subscribe(res => {
        setSnackText('Successfully saved');
        setOpenSnack(true);
        if (condition === 'SUBMITTED') {
          getConfigInfo();
        }
      });
    }
  };

  const getConfigInfo = () => {
    configService.getConfigDetails(state.configName, state.clientType).subscribe(el => {
      if (el.step) {
        setStep(el.step);
        populateConfig(el);
      }
      if (!el.step) {
        setStep('FIRST');
        populateDefault1();
      }
    });
  };

  const onDragEnd = data => {
    setFieldList(data);
  };

  // const getTypeStatus = (value) => {
  //     if (usedTypes.indexOf(value) > -1) {
  //         return true
  //     } else {
  //         return false

  //     }

  // }

  const getdisabledStatus = code => {
    if (code !== 'CORPORATECODE' && code !== 'RETAILCODE') {
      let st = false;
      fieldList.forEach(ele => {
        if (ele.name === code) {
          st = true;
        }
      });
      return st;
    }

    if (code === 'CORPORATECODE' && state.clientType !== 'RETAIL') {
      let st = false;
      fieldList.forEach(ele => {
        if (ele.name === code) {
          st = true;
        }
      });
      return st;
    }

    if (code === 'RETAILCODE' && state.clientType !== 'GROUP') {
      let st = false;
      fieldList.forEach(ele => {
        if (ele.name === code) {
          st = true;
        }
      });
      return st;
    }

    if (code === 'CORPORATECODE' && state.clientType === 'RETAIL') {
      return true;
    } else if (code === 'RETAILCODE' && state.clientType === 'GROUP') {
      return true;
    } else return false;
  };

  const handleSnackClose = (event, reason) => {
    setOpenSnack(false);
  };
  const handleSnackClose1 = (event, reason) => {
    setOpenSnack1(false);
  };

  return (
    <Paper elevation="none">
      <Box p={3} my={2}>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="success">
            {snackText}
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack1} autoHideDuration={6000} onClose={handleSnackClose1}>
          <Alert onClose={handleSnackClose1} severity="error">
            {snackText1}
          </Alert>
        </Snackbar>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: '#000',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            Code Generation Configuration
          </span>
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Code generation for*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="configName"
                value={state.configName}
                onChange={e => handleChange(e)}>
                {codeOptions.map(ele => {
                  return (
                    <MenuItem key={ele.id} value={ele.code}>
                      {ele.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Client Type*</FormLabel>
              <RadioGroup
                aria-label="clientType"
                name="clientType"
                value={state.clientType}
                onChange={handleChange}
                row
                className={classes.prospectImportRadioGroup}>
                <FormControlLabel value="GROUP" control={<Radio />} label="Group" />
                <FormControlLabel value="RETAIL" control={<Radio />} label="Retail" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {state.clientType !== '' && (
          <div>
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                    Character Case*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="characterCase"
                    disabled={step === 'SUBMITTED'}
                    required
                    value={state.characterCase}
                    onChange={e => handleChange(e)}>
                    {caseList.map(ele => {
                      return (
                        <MenuItem key={ele.id} value={ele.value}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                    Separator*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="separator"
                    value={state.separator}
                    disabled={step === 'SUBMITTED'}
                    required
                    onChange={e => handleChange(e)}
                    // error={x.value === null || (x.value === '' && x.name === 'SEPARATOR')}
                    // helperText={x.value === '' ? 'required field' : ' '}
                  >
                    {separatorList.map(ele => {
                      return (
                        <MenuItem key={ele.id} value={ele.value}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                    Sequence Reset Frequency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="sequenceResetFrequency"
                    value={state.sequenceResetFrequency}
                    disabled={step === 'SUBMITTED'}
                    required
                    onChange={e => handleChange(e)}
                    // error={x.value === null || (x.value === '' && x.name === 'SEPARATOR')}
                    // helperText={x.value === '' ? 'required field' : ' '}
                  >
                    {sequenceResetList.map(ele => {
                      return (
                        <MenuItem key={ele.id} value={ele.value}>
                          {ele.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              {step !== 'SUBMITTED' && (
                <Button color="primary" variant="contained" onClick={addNewField}>
                  + Add New Entry
                </Button>
              )}
            </Grid>
            <EO2V2DragNDrop items={fieldList} onChange={onDragEnd}>
              <div>
                {fieldList.map((x, i) => {
                  return (
                    <div id={x.entityId} key={x.entityId}>
                      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                        <Grid item xs={12} container spacing={3} style={{ marginBottom: '20px' }}>
                          <Grid item xs={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="name"
                                value={x.name}
                                disabled={x.name === 'SEQUENCENO' || step === 'SUBMITTED'}
                                onChange={e => handleField(e, i)}>
                                {codeTypes.map(ele => {
                                  return (
                                    <MenuItem key={ele.id} disabled={getdisabledStatus(ele.code)} value={ele.code}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={5}>
                            {x.name && x.name === 'RETAILCODE' && state.clientType === 'RETAIL' && (
                              <TextField
                                id="standard-basic"
                                name="value"
                                value={x.value}
                                maxLength={2}
                                onChange={e => handleField(e, i)}
                                label="Retail Code"
                                disabled={step === 'SUBMITTED'}
                                error={
                                  x.value === null || (x.value === '' && x.name === 'RETAILCODE') || x.value.length !== 2
                                }
                                required
                                helperText={x.value.length !== 2 ? 'Must be 2 character long' : ' '}
                              />
                            )}
                            {x.name && x.name === 'FIXEDTEST' && (
                              <TextField
                                id="standard-basic"
                                name="value"
                                value={x.value}
                                maxLength={2}
                                disabled={step === 'SUBMITTED'}
                                required
                                onChange={e => handleField(e, i)}
                                label="Fixed Text"
                                error={x.value === null || (x.value === '' && x.name === 'FIXEDTEST')}
                                helperText={x.value === '' ? 'required field' : ' '}
                              />
                            )}
                            {x.name && x.name === 'SEQUENCENO' && (
                              <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                  Sequence No.
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="value"
                                  value={x.value}
                                  disabled={step === 'SUBMITTED'}
                                  required
                                  onChange={e => handleField(e, i)}
                                  error={x.value === null || (x.value === '' && x.name === 'SEQUENCENO')}
                                  helperText={x.value === '' ? 'required field' : ' '}>
                                  {sequenceList.map(ele => {
                                    return (
                                      <MenuItem key={ele.id} value={ele.value}>
                                        {ele.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            )}
                            {/* {x.name && x.name === 'SEQUENCE_RESET_FREQUENCY' && (
                              <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                  Sequence Reset Frequency
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="value"
                                  disabled={step === 'SUBMITTED'}
                                  value={x.value}
                                  required
                                  onChange={e => handleField(e, i)}
                                  error={x.value === null || (x.value === '' && x.name === 'SEQUENCE_RESET_FREQUENCY')}
                                  helperText={x.value === '' ? 'required field' : ' '}>
                                  {sequenceResetList.map(ele => {
                                    return (
                                      <MenuItem key={ele.id} value={ele.value}>
                                        {ele.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            )} */}
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}>
                            {/* <Button color="secondary" variant="contained" onClick={() => {handleRemoveField(i)}}>Delete field</Button> */}
                            {fieldList.length !== 1 && (
                              <Tooltip title="Delete Field" placement="top">
                                <Button
                                  className="mr10"
                                  onClick={() => handleRemoveField(i)}
                                  variant="contained"
                                  color="secondary"
                                  disabled={x.name === 'SEQUENCENO' || step === 'SUBMITTED'}
                                  style={{ marginLeft: '5px', height: '40px' }}>
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            )}
                            {fieldList.length - 1 === i && (
                              <Tooltip title="Add Field" placement="top">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ marginLeft: '5px', height: '40px' }}
                                  onClick={addNewField}
                                  disabled={step === 'SUBMITTED'}>
                                  <AddIcon />
                                </Button>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                        <Divider style={{ marginBottom: '20px' }} />
                      </Grid>
                    </div>
                  );
                })}
              </div>
            </EO2V2DragNDrop>
            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              {fieldList.length !== 0 && step !== 'SUBMITTED' && (
                <Button
                  color="primary"
                  style={{ marginRight: '10px' }}
                  variant="contained"
                  onClick={() => saveConfigurations('DRAFT')}>
                  Save as Draft
                </Button>
              )}

              {fieldList.length !== 0 && step !== 'SUBMITTED' && (
                <Button color="primary" variant="contained" onClick={() => saveConfigurations('SUBMITTED')}>
                  Save Configurations
                </Button>
              )}
            </Grid>
          </div>
        )}
      </Box>
    </Paper>
  );
}
