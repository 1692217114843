import { Button } from 'primereact/button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { BenefitStructureService } from '../../remote-api/api/benefit-structure-services';
import { BenefitService } from '../../remote-api/api/master-services';
import { defaultPageRequest } from '../../remote-api/models/page.request';
import { Eo2v2BenefitGraph } from '../../shared-components';
import Asterisk from '../../shared-components/components/red-asterisk';

const benefitService = new BenefitService();
const benefitStructureService = new BenefitStructureService();

const pageRequest = { ...defaultPageRequest };
pageRequest.size = 100000;
const benefitService$ = benefitService.getAllBenefit(pageRequest);

const useStyles = theme => ({});

const hierarchySchema = Yup.object().shape({
  hierarchyName: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Only letters and numbers are allowed')
    .required('Hierarchy name is required'),
});

class BenifitHierarchyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hierarchyName: '', hierarchy: null, benefitStructureStatus: '' };
    this.hierarchyData = {};

    console.log(props);
  }

  componentDidMount = () => {
    if (this.props.match.params.hierarchyId) {
      benefitStructureService.getBenefitStructuresById(this.props.match.params.hierarchyId).subscribe(resp => {
        if (resp.status === 200) {
          this.setState({
            ...this.state,
            hierarchyName: resp.data.description,
            benefitStructureStatus: resp.data.benefitStructureStatus,
            hierarchy: resp.data.hirearchy,
          });
          this.hierarchyData = resp.data.hirearchy;
        }
      });
    }
  };

  datasource = () => benefitService$;

  graphOnchange = data => {
    console.log(data);
    this.hierarchyData = data;
  };

  handleSave = values => {
    if (Object.keys(this.hierarchyData).length) {
      const payload = {
        id: '',
        description: values.hierarchyName,
        hirearchy: this.hierarchyData,
      };
      if (this.props.match.params.hierarchyId) {
        return benefitStructureService.updateBenefitStructures(payload, this.props.match.params.hierarchyId);
      } else {
        return benefitStructureService.saveBenefitStructures(payload);
      }
    } else {
      alert('Create hierarchy first');
    }
  };

  navigateToList = () => {
    this.props.history.push('/masters/benefit-hierarchy?mode=viewList');
  };

  render() {
    return (
      <div>
        {/*  <Typography variant="h6" gutterBottom>Benifit Management: Benefit Hierarchy</Typography> */}
        <Paper elevation="none">
          <Grid container style={{ border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '4px' }}>
            <Eo2v2BenefitGraph
              benefitDataSource$={this.datasource}
              onChange={this.graphOnchange}
              benefit={this.state.hierarchy}
              readOnly={this.state.benefitStructureStatus === 'APPROVED'}
            />
          </Grid>

          <Formik
            enableReinitialize={true}
            initialValues={{
              hierarchyName: this.state.hierarchyName,
              benefitStructureStatus: this.state.benefitStructureStatus,
            }}
            validationSchema={hierarchySchema}
            onSubmit={(values, { resetForm }) => {
              this.handleSave(values).subscribe(resp => {
                this.setState({ ...this.state, hierarchy: null });
                resetForm();
                this.navigateToList();
              });
            }}>
            {({ touched, errors, handleSubmit, handleChange, values }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container style={{ padding: '8px' }}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <TextField
                        name="hierarchyName"
                        label={
                          <span>
                            Hierarchy Name <Asterisk />
                          </span>
                        }
                        value={values.hierarchyName}
                        onChange={handleChange}
                        // required
                        onInput={e => {
                          e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                        }}
                        fullWidth
                        disabled={values.benefitStructureStatus === 'APPROVED'}
                        error={touched.hierarchyName && Boolean(errors.hierarchyName)}
                        helperText={touched.hierarchyName && errors.hierarchyName}
                      />
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: '8px' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={Object.keys(this.hierarchyData).length && values.benefitStructureStatus === 'APPROVED'}
                        // style={{
                        //   color: 'inherit',
                        //   marginRight: '8px',
                        //   boxShadow:
                        //     '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                        //   padding: '6px 16px',
                        //   borderRadius: 4,
                        // }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        className="p-button-text"
                        onClick={this.navigateToList}
                        // style={{
                        //   color: 'inherit',
                        //   boxShadow:
                        //     '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                        //   padding: '6px 16px',
                        //   borderRadius: 4,
                        // }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(BenifitHierarchyComponent));
