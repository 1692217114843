import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import { MenuService } from '../../../../../modules/remote-api/api/miscellaneous';
import IntlMessages from '../../../../utils/IntlMessages';
import { SearchProvider } from '../../../../../@coremat/CmtNavigation/Vertical/SearchContext';
/* import { sidebarNavs } from '../menus'; */

const menuService = new MenuService();

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    // '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
    //   height: 'calc(100% - 167px)',
    // },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const [menuList, setMenuList] = React.useState([]);
  const [filteredMenuList, setFilteredMenuList] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const userType = localStorage.getItem('userType');

  const buildRouteObject = o => {
    return {
      name: <span>{o.displayName}</span>,
      type: o.children && o.children.length > 0 ? 'collapse' : 'item',
      icon: <Icon>{o.icon}</Icon>,
      ...(o.routePath && { link: o.routePath }),
      ...(o.children &&
        o.children.length > 0 && {
          children: o.children.map(c => buildRouteObject(c)),
        }),
    };
  };

  const checkMenuPermission = menuObject => {
    const access_details = JSON.parse(localStorage.getItem('access_details'));
    const status = access_details?.some(role => role.indexOf(menuObject.key.toUpperCase()) > -1);
    /* const status = access_details.some((role) => {
      const moduleName = role.split("_")[1];
      return role.indexOf(menuObject.displayName.toUpperCase()) > -1
        || menuObject.routePath.indexOf(moduleName.toLowerCase()) > -1;
    }); */
    return status;
  };

  React.useEffect(() => {
    if (userType === 'DOCTOR' || userType === 'SURVEILLANCE') {
      menuService.getDoctorMenuList().subscribe(res => {
        let sideNavs = [];
        for (let idx = 0; idx < res.length; idx++) {
          const r = res[idx];
          if (r.children && r.children.length > 0) {
            if (r.visibility) {
              const newObj = { ...r, children: r.children };
              sideNavs.push(buildRouteObject(newObj));
            } else {
              const hasAccess = r.children.filter(c => checkMenuPermission(c) || c.visibility);
              if (hasAccess.length > 0) {
                const newObj = { ...r, children: hasAccess };
                sideNavs.push(buildRouteObject(newObj));
              }
            }
          } else {
            if (checkMenuPermission(r) || r.visibility) {
              sideNavs.push(buildRouteObject(r));
            }
          }
        }
        setMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
        setFilteredMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
      });
    } else if (userType === 'TPA') {
      menuService.getTPAMenuList().subscribe(res => {
        let sideNavs = [];
        for (let idx = 0; idx < res.length; idx++) {
          const r = res[idx];
          if (r.children && r.children.length > 0) {
            if (r.visibility) {
              const newObj = { ...r, children: r.children };
              sideNavs.push(buildRouteObject(newObj));
            } else {
              const hasAccess = r.children.filter(c => checkMenuPermission(c) || c.visibility);
              if (hasAccess.length > 0) {
                const newObj = { ...r, children: hasAccess };
                sideNavs.push(buildRouteObject(newObj));
              }
            }
          } else {
            if (checkMenuPermission(r) || r.visibility) {
              sideNavs.push(buildRouteObject(r));
            }
          }
        }
        setMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
        setFilteredMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
      });
    } else if (userType === 'AGENT') {
      menuService.getAgentMenuList().subscribe(res => {
        let sideNavs = [];
        for (let idx = 0; idx < res.length; idx++) {
          const r = res[idx];
          if (r.children && r.children.length > 0) {
            if (r.visibility) {
              const newObj = { ...r, children: r.children };
              sideNavs.push(buildRouteObject(newObj));
            } else {
              const hasAccess = r.children.filter(c => checkMenuPermission(c) || c.visibility);
              if (hasAccess.length > 0) {
                const newObj = { ...r, children: hasAccess };
                sideNavs.push(buildRouteObject(newObj));
              }
            }
          } else {
            if (checkMenuPermission(r) || r.visibility) {
              sideNavs.push(buildRouteObject(r));
            }
          }
        }
        setMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
        setFilteredMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
      });
    } else {
      menuService.getMenuList().subscribe(res => {
        /* const sideNavs = res.filter(r => {
          if (r.children && r.children.length > 0) {
            return r.children.filter(c => checkMenuPermission(c));
          } else {
            return checkMenuPermission(r);
          }
        }).map(r => {
          return buildRouteObject(r);
        }); */

        let sideNavs = [];
        for (let idx = 0; idx < res.length; idx++) {
          const r = res[idx];
          if (r.children && r.children.length > 0) {
            if (r.visibility) {
              const newObj = { ...r, children: r.children };
              sideNavs.push(buildRouteObject(newObj));
            } else {
              const hasAccess = r.children.filter(c => checkMenuPermission(c) || c.visibility);
              if (hasAccess.length > 0) {
                const newObj = { ...r, children: hasAccess };
                sideNavs.push(buildRouteObject(newObj));
              }
            }
          } else {
            if (checkMenuPermission(r) || r.visibility) {
              sideNavs.push(buildRouteObject(r));
            }
          }
        }
        setMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
        setFilteredMenuList([
          {
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs,
          },
        ]);
      });
    }
  }, []);

  const recursiveSearch = (item, term) => {
    if (item.type === 'item') {
      if (item.name && item.name.props && item.name.props.children) {
        const itemName = item.name.props.children.toString().toLowerCase();
        if (itemName.includes(term.toLowerCase())) {
          return true;
        }
      }
    } else if (item.children && item.children.length > 0) {
      for (const childItem of item.children) {
        if (recursiveSearch(childItem, term)) {
          return true;
        }
      }
    }
    return false;
  };

  React.useEffect(() => {
    if (!!searchTerm) {
      setFilteredMenuList(prevList => [
        {
          ...prevList[0],
          children: menuList[0].children.filter(item => recursiveSearch(item, searchTerm)),
        },
      ]);
    } else {
      setFilteredMenuList(prevList => [
        {
          ...prevList[0],
          children: menuList,
        },
      ]);
    }
  }, [searchTerm, menuList]);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <SearchProvider>
      <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
        <CmtVertical menuItems={filteredMenuList} handleSearchChange={handleSearchChange} searchTerm={searchTerm} />
      </PerfectScrollbar>
    </SearchProvider>
  );
};

export default SideBar;

/* class SideBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuList: []
    };
  }

  componentDidMount() {
    menuService.getMenuList()
      .subscribe(res => {
        const sideNavs = res.map(r => {
          return this.buildRouteObject(r);
        });
        this.setState({
          ...this.state,
          menuList: [{
            name: <IntlMessages id={'sidebar.main'} />,
            type: 'section',
            children: sideNavs
          }]
        });

      });
  }

  buildRouteObject = (o) => {
    return {
      name: <span>{o.displayName}</span>,
      type: o.children && o.children.length > 0 ? 'collapse' : 'item',
      icon: <Icon>{o.icon}</Icon>,
      ...(o.routePath && { link: o.routePath }),
      ...(o.children && o.children.length > 0 && { children: o.children.map(c => this.buildRouteObject(c)) })
    };
  };

  render() {
    const { classes } = this.props;

    return (
      <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
        <CmtVertical menuItems={this.state.menuList} />
      </PerfectScrollbar>
    );
  }
}
export default withRouter(withStyles(useStyles)(SideBar)); */
