import { Box, Breadcrumbs, lighten, Link, Snackbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  clearSelectedRuleFromBenefitInNavPath,
  extractPremiumRulesFromBenefitStructures,
  getSelectedRuleId,
  hasAnyPremiumRuleInBenefitHierarchies,
  setPremiumDetailsInProductRules,
  setRulesInBenefitStructures,
} from '../../product-management/util/product-util';

import { zip } from 'rxjs';
import {
  BenefitService,
  BenefitStructureService,
  PremiumFrequencyService,
  ProductService,
} from '../../remote-api/eo2v2-remote-api';
import ProductRuleTableForPremium from './product-rule-table-for-premium';
import { Button } from 'primereact/button';

const benefitStructureService = new BenefitStructureService();
const benefitService = new BenefitService();
const productservice = new ProductService();
const premiumFrequencyService = new PremiumFrequencyService();

const useStyles = makeStyles(theme => ({
  handleSubBenefitsScroll: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  benifitDesignRoot: {
    flexGrow: 1,
    minHeight: 100,
    padding: 30,
  },
  header: {
    paddingTop: 10,
    paddingBottom: 10,
    color: '#4472C4',
  },
  mainBenifitAction: {
    borderRadius: 14,
    backgroundColor: lighten(theme.palette.primary.light, 0.9) /* "#aceae8" */,
    '&.MuiGrid-item': {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  mainBenifitActionLbl: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainBenifitBtnSection: {
    display: 'flex',
    /* justifyContent: "space-evenly", */
  },
  subBenifitsSection: {
    marginTop: 10,
  },
  subBenifitLabel: {
    textAlign: 'center',
    fontSize: 14,
  },
  subBenifitsMenuList: {
    backgroundColor: lighten(theme.palette.primary.light, 0.9) /* "#aceae8" */,
    height: 300,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 14,
  },
  subBenifitTabs: {
    flex: 1,
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  subBenifitTab: {
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  },
  subBenifitsMenu: {
    fontSize: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

function a11yProps(index, prefix) {
  return {
    id: `main-benifit-tab-${index}-${prefix}`,
    'aria-controls': `main-benifit-tabpanel-${index}-${prefix}`,
  };
}
const PremiumDesignFormComponent = props => {
  const classes = useStyles();
  const { productId } = useParams();
  const [selectedBenefitStructureIndex, setSelectedBenefitStructureIndex] = React.useState(0);
  const [benefitStructures, setBenefitStructures] = React.useState([]);
  const [selectedBenefitIndex, setSelectedBenefitIndex] = React.useState(0);
  const [benefitList, setBenefitList] = useState([]);
  const [isOpenRulePreviewModal, setIsOpenRulePreviewModal] = useState(false);
  const selectedBenefitStrucute = useRef(null);
  const navPath = useRef(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const productDetails = useRef(null);
  const paymentFrequencies = useRef([]);
  const [disableSaveButon, setDisableSaveButton] = useState(false);

  const onSetRootBenefit = (rootBenefit, index = 0) => {
    if (navPath.current) {
      clearSelectedRuleFromBenefitInNavPath(navPath.current);
    }
    navPath.current = [rootBenefit];
    selectedBenefitStrucute.current = rootBenefit;
    rootBenefit.ruleList = rootBenefit.ruleList || [];

    if (!rootBenefit?.parameters) {
      benefitService.getBenefitParameterDetails(rootBenefit?.code).subscribe(response => {
        rootBenefit.parameters = response?.parameters;

        setBenefitList(
          [rootBenefit]?.map(ele => {
            return {
              ...ele,
              child: [
                ...ele?.child?.filter(val => val.ruleList.length > 0),
                ...ele?.child?.filter(val => val.ruleList.length === 0),
              ],
            };
          }),
        );
        setSelectedBenefitStructureIndex(index);
        setSelectedBenefitIndex(0);
      });
    } else {
      setBenefitList(
        [rootBenefit]?.map(ele => {
          return {
            ...ele,
            child: [
              ...ele?.child?.filter(val => val.ruleList.length > 0),
              ...ele?.child?.filter(val => val.ruleList.length === 0),
            ],
          };
        }),
      );
      setSelectedBenefitStructureIndex(index);
      setSelectedBenefitIndex(0);
    }
  };

  const handleBenefitStructureTabChange = (event, index) => {
    onSetRootBenefit(benefitStructures[index].hirearchy, index);
  };

  const handleBenefitTabChange = (event, index) => {
    const benefit = benefitList[index];
    navPath.current[navPath.current.length - 1] = benefit;
    benefit.ruleList = benefit.ruleList || [];

    if (!benefit.parameters) {
      benefitService.getBenefitParameterDetails(benefit.code).subscribe(response => {
        benefit.parameters = response.parameters;
        setSelectedBenefitIndex(index);
      });
    } else {
      setSelectedBenefitIndex(index);
    }
  };

  const getAllBenefitStructures = () => {
    const benefitStructureService$ = benefitStructureService.getAllBenefitStructures();
    return benefitStructureService$;
  };

  useEffect(() => {
    const productDetails$ = productservice.getProductDetails(productId);
    const premiumRules$ = productservice.getPremiums(productId);
    const benefitStructures$ = getAllBenefitStructures();
    const freq$ = premiumFrequencyService.getPremiumFrequencies();

    var subscriber = zip(productDetails$, benefitStructures$, premiumRules$, freq$).subscribe(res => {
      const [pd, page, premiums, freq] = res;
      productDetails.current = pd;
      setPremiumDetailsInProductRules(pd, premiums.premiumRules);
      paymentFrequencies.current = freq.content;
      setRulesInBenefitStructures(page.content, productDetails.current.productRules || []);

      setBenefitStructures(page.content);

      onSetRootBenefit(page.content[0].hirearchy);

      setDisableSaveButton(!hasAnyPremiumRuleInBenefitHierarchies(page.content));
    });
    return () => subscriber.unsubscribe();
  }, []);

  const onSelectBenefitFromNavPath = benefit => {
    const index = navPath.current.indexOf(benefit);

    if (index == 0) {
      onSetRootBenefit(benefit, selectedBenefitStructureIndex);
    } else {
      clearSelectedRuleFromBenefitInNavPath(navPath.current, index);
      const parentBenefit = navPath.current[index - 1];
      navPath.current = navPath.current.slice(0, index + 1);
      setBenefitList(parentBenefit.child);
      setSelectedBenefitIndex(parentBenefit.child.indexOf(benefit));
    }
  };

  const buildBreadcrumb = () => {
    if (navPath.current && navPath.current.length > 0) {
      return (
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          {navPath.current.map((path, index) => {
            if (index === navPath.current.length - 1) return <Typography color="text.primary">{path.name}</Typography>;
            else {
              return (
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={e => {
                    e.stopPropagation();
                    onSelectBenefitFromNavPath(path);
                  }}>
                  {path.name}
                </Link>
              );
            }
          })}
          ;
        </Breadcrumbs>
      );
    } else return null;
  };

  /* request for adding child rule*/
  const onRuleSelect = rule => {
    const benefit = benefitList[selectedBenefitIndex];
    if (benefit.child) {
      rule.isSelected = true;
      const tobeBenefit = benefit.child[0];
      tobeBenefit.ruleList = tobeBenefit.ruleList || [];
      navPath.current = [...navPath.current, tobeBenefit];
      if (tobeBenefit.parameters) {
        setBenefitList(benefit.child);
        setSelectedBenefitIndex(0);
      } else {
        benefitService.getBenefitParameterDetails(tobeBenefit.code).subscribe(response => {
          tobeBenefit.parameters = response.parameters;
          setBenefitList(benefit.child);
          setSelectedBenefitIndex(0);
        });
      }
    }
  };

  const getRuleListForRuleViewTable = () => {
    if (!navPath.current) {
      return [];
    }
    if (navPath.current.length == 1) {
      return benefitList[selectedBenefitIndex].ruleList;
    }

    const parentBenefit = navPath.current[navPath.current.length - 2];
    const selectedRuleId = getSelectedRuleId(parentBenefit);
    return benefitList[selectedBenefitIndex].ruleList.filter(r => r.parentInternalId === selectedRuleId);
  };

  const saveRule = () => {
    const rules = extractPremiumRulesFromBenefitStructures(benefitStructures);
    productservice.savePremiums(productId, rules).subscribe(() => {
      setOpenSnackbar(true);
    });
  };

  const onBenefitChange = benefit => {
    setDisableSaveButton(!hasAnyPremiumRuleInBenefitHierarchies(benefitStructures));
  };
  return (
    <div style={{ padding: '5px' }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant="filled">
          Product updated successfully
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            <span>
              Premium Design for : <strong>{productDetails.current?.productBasicDetails?.name}</strong>{' '}
            </span>
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={1}>
          <Grid item>{/* <h4>Main Benifits</h4> */}</Grid>
          <Grid item xs={10}>
            <Box>
              <Tabs
                value={selectedBenefitStructureIndex}
                centered
                indicatorColor="primary"
                textColor="primary"
                onChange={handleBenefitStructureTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Main Benifit Tabs">
                {benefitStructures?.map((item, index) => {
                  const ruleListLength = item?.hirearchy?.ruleList.length;
                  return ruleListLength > 0 ? (
                    <Tab
                      textColor="inherit"
                      style={{ fontSize: '12px' }}
                      label={item.description}
                      {...a11yProps(index, 'benefit-structure')}
                      key={item.id}
                    />
                  ) : (
                    ''
                  );
                })}
              </Tabs>
            </Box>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            {/* sub menu list -- start*/}
            <Grid item xs={3}>
              <TextField id="standard-basic" style={{ padding: '5px 15px' }} fullWidth placeholder="Search benefit" />
              <Tabs
                style={{ height: '400px', overflowY: 'scroll' }}
                indicatorColor="primary"
                orientation="vertical"
                variant="scrollable"
                aria-label="Vertical tabs example"
                className={`${classes.subBenifitTabs} ${classes.handleSubBenefitsScroll}`}
                value={selectedBenefitIndex}
                onChange={handleBenefitTabChange}>
                {benefitList &&
                  benefitList?.map((subBenifit, idx) => {
                    return (
                      <Tab
                        label={subBenifit.name}
                        {...a11yProps(idx, 'benefit')}
                        key={subBenifit.id}
                        style={{ color: subBenifit?.ruleList?.length > 0 ? '#3a7cff' : '' }}
                        className={classes.subBenifitTab}
                      />
                    );
                  })}
              </Tabs>
            </Grid>

            {/* sub menu list -- end*/}
            <Grid item xs={9} container spacing={1}>
              <Grid item xs={12}>
                <Paper variant="outlined" square>
                  {buildBreadcrumb()}
                </Paper>
              </Grid>

              {/* <Grid item xs={4} container spacing={1}>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" style={{ float: "right" }} onClick={() => {
                                        setIsOpenRuleModal(true);
                                    }} >Add Rule</Button>
                                    <RuleDesignModal openDialog={isOpenRuleModal} setOpenDialog={setIsOpenRuleModal}
                                        forBenefit={benefitList[selectedBenefitIndex]} key={isOpenRuleModal}
                                        benefitNav={navPath.current || []} onAdd={onRuleAdd}></RuleDesignModal>
                                </Grid>
                            </Grid> */}

              <Grid item xs={12}>
                <Paper variant="outlined" square>
                  {benefitList && benefitList[selectedBenefitIndex] && (
                    <ProductRuleTableForPremium
                      forBenefit={benefitList[selectedBenefitIndex]}
                      ruleList={getRuleListForRuleViewTable()}
                      onRequestForChildRule={onRuleSelect}
                      hasChild={
                        benefitList[selectedBenefitIndex].child && benefitList[selectedBenefitIndex].child.length > 0
                      }
                      paymentFrequencies={paymentFrequencies.current}
                      onBenefitChange={onBenefitChange}></ProductRuleTableForPremium>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  className="p-button-outlined"
                  style={{ float: 'right' }}
                  disabled={disableSaveButon}
                  onClick={saveRule}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default withRouter(PremiumDesignFormComponent);
