import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ProductManagementForm from './product.management.form';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProductsActionComponent(props) {
  const query = useQuery();
  let history = useHistory();

  return (
    <div>
      {(() => {
        switch (query.get('mode')) {
          case 'edit':
            return <ProductManagementForm />;
          default:
            history.push(`/products/${props.match.params.id}?mode=edit`);
        }
      })()}
    </div>
  );
}
