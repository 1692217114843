import DateFnsUtils from '@date-io/date-fns';
// import { Button } from 'primereact/button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { forkJoin } from 'rxjs';
import { ClientService, ProspectService } from '../../../remote-api/api/client-services';
import {
  ClientTypeService,
  GroupTypeService,
  PrefixTypeService,
  SuffixTypeService,
} from '../../../remote-api/api/master-services';
import { QuotationService } from '../../../remote-api/api/quotation-services';
import { PolicyService } from '../../../remote-api/eo2v2-remote-api';
import { EO2V2Autocomplete } from '../../../shared-components';
import { Button } from 'primereact/button';

const prospectService = new ProspectService();
const quotationService = new QuotationService();
const clientTypeService = new ClientTypeService();
const grouptypeService = new GroupTypeService();
const prefixservice = new PrefixTypeService();
const suffixservice = new SuffixTypeService();
const policyService = new PolicyService();
const clientService = new ClientService();
const useStyles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
  tagBlock: {},
  aLabel: {
    display: 'flex',
    alignItems: 'center',
  },
});

function isLeapYear(year) {
  // Leap year conditions
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    return true;
  }

  return false;
}

const today = new Date();
today.setUTCHours(0, 0, 0, 0);
const endDate = new Date(today);
const startYear = today.getFullYear();
// if (isLeapYear(startYear)) {
//   endDate.setDate(today.getDate() + 364);
// } else {
endDate.setDate(today.getDate() + 364);
// }
endDate.setUTCHours(0, 0, 0, 0);

class QuotationBasicDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationManagementForm: {
        clientType: '',
        groupType: '',
        prefix: '',
        firstName: '',
        middletName: '',
        lastName: '',
        suffix: '',
        displayName: '',
        mobileNo: '',
        alternateMobileNo: '',
        emailId: '',
        alternateEmailId: '',
        addresses: '',
        code: '',
      },
      tag: '',
      policyDuration: 365,
      policyStartDate: new Date(),
      policyEndDate: endDate,
      // policyEndDate: new Date(today.getDate() + 365),
      selectedProspect: '',
      clientTypes: [],
      groupTypes: [],
      prefixes: [],
      suffixes: [],
    };
  }

  componentDidMount() {
    const APIs = [this.getCleintTypes(), this.getGroupTypes(), this.getPrefixTypes(), this.getSuffixTypes()];
    forkJoin(APIs).subscribe(res => {
      this.setState({
        ...this.state,
        clientTypes: res[0].content,
        groupTypes: res[1].content,
        prefixes: res[2].content,
        suffixes: res[3].content,
      });
      const query = new URLSearchParams(this.props.location.search);
      if (query.get('policyId')) {
        this.getRenewalDetails();
      } else {
        this.setNewProspect();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quotationDetails != this.props.quotationDetails) {
      const prospectId = this.props.quotationDetails.prospectId;
      this.populateBasicDetails();
      localStorage.setItem('prospectID', prospectId);
    }
  }

  isProspectDisabled = () => {
    return (
      this.props.quotationDetails.memberUploadStatus === 'COMPLETED' ||
      this.props.quotationDetails.premiumCalculationStatus === 'COMPLETED'
    );
  };

  populateBasicDetails = () => {
    const prospectId = this.props.quotationDetails.prospectId;
    this.setState({
      ...this.state,
      tag: this.props.quotationDetails.tag,
      selectedProspect: prospectId,
      policyDuration: this.props.quotationDetails.policyDuration,
      policyStartDate: this.props.quotationDetails.policyStartDate
        ? new Date(this.props.quotationDetails.policyStartDate)
        : new Date(),
      policyEndDate: this.props.quotationDetails.policyEndDate
        ? new Date(this.props.quotationDetails.policyEndDate)
        : new Date(),
    });
    this.getProspectDetails(prospectId);
  };

  getCleintTypes = () => {
    return clientTypeService.getCleintTypes();
  };

  getGroupTypes = () => {
    return grouptypeService.getGroupTypes();
  };

  getPrefixTypes = () => {
    return prefixservice.getPrefixTypes();
  };

  getSuffixTypes = () => {
    return suffixservice.getSuffixTypes();
  };

  setNewProspect = () => {
    const query = new URLSearchParams(this.props.location.search);
    const prospectId = query.get('prospectId');
    if (prospectId) {
      this.setState({
        ...this.state,
        selectedProspect: prospectId,
      });
      this.getProspectDetails(prospectId);
    }
  };

  getRenewalDetails() {
    const query = new URLSearchParams(this.props.location.search);
    if (query.get('policyId')) {
      policyService.getPolicyDetails(query.get('policyId')).subscribe(policy => {
        if (policy.clientId) {
          this.getClientDetails(policy.clientId);
        }
      });
    }
  }

  getClientDetails(clientId) {
    clientService.getClientDetails(clientId).subscribe(res => {
      if (res.clientBasicDetails && res.prospectId) {
        let prospectID = res.prospectId;
        this.setState({
          ...this.state,
          selectedProspect: prospectID,
        });
        localStorage.setItem('prospectID', prospectID);
        this.getProspectDetails(prospectID);
      }
    });
  }

  prospectDataSourceCallback$ = (
    params = {},
    action = '',
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
  ) => {
    let reqParam = { ...pageRequest, ...params };
    if (action.searchText) {
      reqParam = {
        ...reqParam,
        code: action.searchText,
        displayName: action.searchText,
        mobileNo: action.searchText,
      };
      delete reqParam.active;
    }
    return prospectService.getProspects(reqParam);
  };

  handleAutoComplete = (name, e, value) => {
    this.getProspectDetails(value.id);
    this.setState({
      ...this.state,
      selectedProspect: value.id,
    });
    localStorage.setItem('prospectID', value.id);
  };

  getProspectDetails = id => {
    if (id) {
      prospectService.getProspectDetails(id).subscribe(result => {
        this.setState({
          ...this.state,
          quotationManagementForm: {
            ...this.state.quotationManagementForm,
            ...result,
            addresses: result?.addresses[0]?.addressDetails?.AddressLine1,
          },
        });
      });
    }
  };

  createProspect = () => {
    this.props.history.push('/client/prospects?mode=create&navigate=quotation');
  };

  handleQmChange = e => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  saveQuotation = () => {
    console.log(this.state);
    const paylaod = {
      prospectId: this.state.selectedProspect,
      tag: this.state.tag,
      policyStartDate: new Date(this.state.policyStartDate.getTime()).getTime(),
      policyEndDate: new Date(this.state.policyEndDate.getTime()).getTime(),
    };
    const query = new URLSearchParams(this.props.location.search);
    if (query.get('policyId')) {
      paylaod['renewalPolicyId'] = query.get('policyId');
    }
    const quotationId = localStorage.getItem('quotationId');

    if (quotationId) {
      const pageRequest = {
        action: 'update-basic',
      };

      quotationService.updateQuotation(pageRequest, paylaod, quotationId).subscribe(res => {
        this.props.handleNext();
      });
    } else {
      quotationService.saveQuotation(paylaod).subscribe(res => {
        localStorage.setItem('quotationId', res.id);
        this.props.handleNext();
      });
    }
  };

  handleEndDateChange = date => {
    this.setState({ policyEndDate: date });
  };

  handleStartDateChange = date => {
    // this.setState({ ...this.state, policyStartDate: date });
    let startDate = new Date(date);
    startDate.setUTCHours(0, 0, 0, 0);
    this.setState({ policyStartDate: startDate });
    // const endDate = new Date(date);

    // Add 365 days to the end date
    // endDate.setDate(date.getDate() + 365);

    const endDate = new Date(date);
    const startYear = date.getFullYear();
    endDate.setUTCHours(0, 0, 0, 0);

    // if (isLeapYear(startYear)) {
    // endDate.setDate(date.getDate() + 364);
    // } else {
    endDate.setDate(date.getDate() + 364);
    // }
    this.setState({ policyEndDate: endDate });
  };

  render() {
    const { classes } = this.props;
    const {
      clientTypes,
      groupTypes,
      prefixes,
      suffixes,
      tag,
      policyDuration,
      policyStartDate,
      policyEndDate,
      selectedProspect,
      quotationManagementForm,
    } = this.state;

    const query = new URLSearchParams(this.props.location.search);

    return (
      <div>
        <div className={classes.header} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" gutterBottom>
            Quotation Management - Create Quotation
          </Typography>
          <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize' }}>
            {query.get('type')}
          </Typography>
        </div>
        <Paper elevation="none" style={{ padding: 15 }}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField id="tag" name="tag" label="Tag" value={tag} onChange={this.handleQmChange} />
              </FormControl>
            </Grid>

            <Grid container item xs={12} sm={6} md={4}>
              <Grid item xs={12}>
                <Typography variant="body2">Already created a prospect?</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <EO2V2Autocomplete
                    id="prospect"
                    name="prospect"
                    label="Search Prospect"
                    $dataSource={this.prospectDataSourceCallback$}
                    displayKey="displayName"
                    value={selectedProspect}
                    changeDetect={true}
                    onChange={(e, newValue) => this.handleAutoComplete('selectedProspect', e, newValue)}
                    disabled={this.isProspectDisabled()}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container xs={12} sm={6} md={4}>
              <Grid item xs={12}>
                <Typography variant="body2">Prospect not created?</Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '18px' }} container alignItems="flex-end">
                <Button variant="contained" color="primary" onClick={this.createProspect}>
                  Create Prospect
                </Button>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <FormControl>
                <TextField
                  id="policy-duration"
                  name="policyDuration"
                  label="Policy Duration (In days)"
                  value={policyDuration}
                  onChange={this.handleQmChange}
                />
              </FormControl>
            </Grid> */}

            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <Grid container justify="space-around"> */}
                  <KeyboardDatePicker
                    views={['year', 'month', 'date']}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk={true}
                    id="date-picker-inline"
                    label="Policy Start Date"
                    value={policyStartDate}
                    onChange={this.handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change ing date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <Grid container justify="space-around"> */}
                  <KeyboardDatePicker
                    views={['year', 'month', 'date']}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk={true}
                    id="date-picker-inline"
                    label="Policy End Date"
                    value={policyEndDate}
                    onChange={this.handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change ing date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation="none" style={{ padding: 15, marginTop: 20 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="client-type-label" style={{ marginBottom: '0px' }}>
                  Client type
                </InputLabel>
                <Select
                  labelId="client-type-label"
                  id="client-type-select"
                  name="clientType"
                  value={quotationManagementForm.clientType}
                  disabled>
                  {clientTypes.map(ele => {
                    return (
                      <MenuItem key={ele.id} value={ele.code}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}></Grid> */}
            {quotationManagementForm.groupType && (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="group-type-label" style={{ marginBottom: '0px' }}>
                    Group type
                  </InputLabel>
                  <Select
                    labelId="group-type-label"
                    id="group-type-select"
                    name="groupType"
                    value={quotationManagementForm.groupType}
                    disabled>
                    {groupTypes.map(ele => {
                      return (
                        <MenuItem key={ele.id} value={ele.code}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          {!quotationManagementForm.groupType && (
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="prefix-select-label" style={{ marginBottom: '0px' }}>
                      Prefix
                    </InputLabel>
                    <Select labelId="prefix-select-label" name="prefix" value={quotationManagementForm.prefix} disabled>
                      {prefixes.map(ele => {
                        return (
                          <MenuItem key={ele.id} value={ele.code}>
                            {ele.abbreviation}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="first-name"
                      name="firstName"
                      label="First Name"
                      value={quotationManagementForm.firstName ? quotationManagementForm.firstName : ''}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="middle-name"
                      name="middleName"
                      label="Middle Name"
                      value={quotationManagementForm.middletName ? quotationManagementForm.middletName : ''}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="last-name"
                      name="lastName"
                      label="Last Name"
                      value={quotationManagementForm.lastName ? quotationManagementForm.lastName : ''}
                      disabled
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="suffix-select-label" style={{ marginBottom: '0px' }}>
                      Suffix
                    </InputLabel>
                    <Select labelId="suffix-select-label" name="suffix" value={quotationManagementForm.suffix} disabled>
                      {suffixes.map(ele => {
                        return (
                          <MenuItem key={ele.id} value={ele.id}>
                            {ele.abbreviation}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="displayName"
                      name="displayName"
                      label="Display Name"
                      value={quotationManagementForm.displayName ? quotationManagementForm.displayName : ''}
                      disabled
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {quotationManagementForm.groupType && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="first-name"
                    name="firstName"
                    label="Name"
                    value={quotationManagementForm.firstName ? quotationManagementForm.firstName : ''}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="displayName"
                    name="displayName"
                    label="Display Name"
                    value={quotationManagementForm.displayName ? quotationManagementForm.displayName : ''}
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="contact-no"
                  name="contactNo"
                  label="Contact No"
                  value={quotationManagementForm.mobileNo ? quotationManagementForm.mobileNo : ''}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="email-id"
                  name="emailId"
                  label="Email Id"
                  value={quotationManagementForm.emailId ? quotationManagementForm.emailId : ''}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="alt-contact-no"
                  name="altContactNo"
                  label="Alternate Contact No"
                  value={quotationManagementForm.alternateMobileNo ? quotationManagementForm.alternateMobileNo : ''}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="alt-email-id"
                  name="altEmailId"
                  label="Alternate Email Id"
                  value={quotationManagementForm.alternateEmailId ? quotationManagementForm.alternateEmailId : ''}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: '95%' }}>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  multiline
                  maxRows={10}
                  value={quotationManagementForm.addresses ? quotationManagementForm.addresses : ''}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} container justifyContent="flex-end">
              <Button variant="contained" color="secondary" onClick={this.saveQuotation}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(QuotationBasicDetailsComponent));
