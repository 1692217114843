import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BenifitHierarchyComponent from './benefit.hierarchy.component';
import BenefitHierarchyMainComponent from './benefit.hierarchy.main';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function BenefitManagement(props) {
  const query = useQuery();
  const history = useHistory();

  return (
    <div>
      {query.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: 'inherit',
            fontSize: '18px',
          }}>
          <span
            style={{
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            Benefit Hierarchy Management - Create Product
          </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <BenefitHierarchyMainComponent />;
          case 'create':
            return <BenifitHierarchyComponent />;
          default:
            history.push('/masters/benefit-hierarchy?mode=viewList');
        }
      })()}
    </div>
  );
}
