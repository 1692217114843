import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import ProductManagementComponent from './components/product-management.component';
import ProductsActionComponent from './components/product.action.component';

const roleService = new RoleService();

export default function ProductsRoutersComponent(props) {
  const { path } = useRouteMatch();
  const hasAccess = roleService.checkRouteAccess(props, 'PRODUCT');

  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <ProductManagementComponent />{' '}
          </Route>
          <Route exact path="/products" component={ProductManagementComponent} />
          <Route exact path="/products/:productId" component={ProductsActionComponent} />
        </Switch>
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
