import { MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { useKeycloak } from '@react-keycloak/web';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '16px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 0,
    transition: 'all 0.1s ease',
    height: 50,
    color: 'white',
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    // color: props => props.sidebarTheme.textDarkColor,
    color: 'white',
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    keycloak.logout();
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  return (
    <></>
    // <div className={classes.root}>
    //   {/* <CmtAvatar src={'https://via.placeholder.com/150'} alt="User Avatar" /> */}
    //   <div className={classes.userInfo} onClick={handlePopoverOpen}>
    //     <div
    //       className="pointer"
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'space-between',
    //         alignItems: 'flex-end',
    //       }}>
    //       <div className="mr-2">
    //         <Typography className={classes.userTitle} component="h3" variant="h6">
    //           {user_details?.name}
    //         </Typography>
    //         <Typography className={classes.userSubTitle}>{user_details?.email}</Typography>
    //       </div>
    //       <ArrowDropDownIcon />
    //     </div>
    //   </div>

    //   {open && (
    //     <Popover
    //       open={open}
    //       anchorEl={anchorEl}
    //       container={anchorEl}
    //       onClose={handlePopoverClose}
    //       anchorOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       }}
    //       transformOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       }}>
    //       <Paper elevation={2}>
    //         <MenuList>
    //           <MenuItem onClick={handlePopoverClose}>
    //             <PersonIcon />
    //             <div className="ml-2">Profile</div>
    //           </MenuItem>
    //           <MenuItem onClick={handlePopoverClose}>
    //             <SettingsIcon />
    //             <div className="ml-2">Settings</div>
    //           </MenuItem>
    //           <MenuItem onClick={onLogoutClick}>
    //             <ExitToAppIcon />
    //             <div className="ml-2">Logout</div>
    //           </MenuItem>
    //         </MenuList>
    //       </Paper>
    //     </Popover>
    //   )}
    // </div>
  );
};

export default SidebarHeader;
