import React, { useContext, useEffect } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import { useSearchContext } from './SearchContext';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
  root: {
    padding: '16px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 0,
    transition: 'all 0.1s ease',
    height: 50,
    color: 'white',
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    // color: props => props.sidebarTheme.textDarkColor,
    color: '#fafafa',
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    color: '#fafafa',
    fontWeight: theme.typography.fontWeightLight,
    letterSpacing: 0.25,
  },
}));

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      '& input': {
        color: 'white', // Input text color
      },
      '& .MuiInputLabel-root': {
        color: 'white', // Placeholder color
      },
      '& .MuiOutlinedInput-input::placeholder': {
        color: 'white', // Placeholder text color
      },
    },
  },
})(TextField);

const CmtVertical = props => {
  const { menuItems, handleSearchChange, searchTerm } = props;
  // const classes = useStyles();
  const { setSearchTerm } = useSearchContext();

  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    // alert('clicked');
    keycloak.logout();
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  return (
    <>
      <div className={classes.root}>
        {/* <CmtAvatar src={'https://via.placeholder.com/150'} alt="User Avatar" /> */}
        <div className={classes.userInfo} onClick={handlePopoverOpen}>
          <div
            className="pointer"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}>
            <div className="mr-2">
              <Typography className={classes.userTitle} component="h3" variant="h6">
                {user_details?.name}
              </Typography>
              <Typography className={classes.userSubTitle}>{user_details?.email}</Typography>
            </div>
            <ArrowDropDownIcon />
          </div>
        </div>

        {open && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            container={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Paper elevation={2}>
              <MenuList>
                <MenuItem onClick={handlePopoverClose}>
                  <PersonIcon />
                  <div className="ml-2">Profile</div>
                </MenuItem>
                <MenuItem onClick={handlePopoverClose}>
                  <SettingsIcon />
                  <div className="ml-2">Settings</div>
                </MenuItem>
                <MenuItem onClick={onLogoutClick}>
                  <ExitToAppIcon />
                  <div className="ml-2">Logout</div>
                </MenuItem>
              </MenuList>
            </Paper>
          </Popover>
        )}
      </div>
      <Grid container>
        <CustomTextField
          placeholder="Search"
          variant="outlined"
          // fullWidth
          style={{
            background: '#666cb2',
            height: '44px',
            borderRadius: '50px',
            marginInline: '14px',
            width: '270px',
          }}
          value={searchTerm}
          onChange={e => {
            handleSearchChange(e);
            setSearchTerm(e.target.value);
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            style: { paddingLeft: '5px', height: '41px' },
          }}
        />
        {/* <TextField
          placeholder="Search"
          variant="outlined"
          fullWidth
          style={{ background: '#666cb2', borderRadius: '50px', margin: '0 10px' }}
          value={searchTerm}
          onChange={e => {
            handleSearchChange(e);
            setSearchTerm(e.target.value);
          }}
          InputLabelProps={{
            style: { color: 'white' },
          }}
          InputProps={{
            style: { paddingLeft: '5px' },
          }}
        /> */}

        <IconButton
          style={{ position: 'relative', marginLeft: '-64px', color: 'white', height: '47px' }}
          aria-label="clear search"
          onClick={i => {
            handleSearchChange({ target: { value: '' } });
            setSearchTerm('');
          }}
          edge="end">
          {searchTerm ? (
            <CloseIcon />
          ) : (
            <Grid item>
              <SearchIcon style={{ color: 'white', marginRight: '7px' }} />
            </Grid>
          )}
        </IconButton>
      </Grid>

      <List component="nav" disablePadding className={classes.sideNavMenu}>
        {menuItems.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} />;
            case 'item':
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </List>
    </>
  );
};

export default CmtVertical;
