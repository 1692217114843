import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ProductComponent from './product.component';
import ProductManagementForm from './product.management.form';

function useQuery() {
  const value = useLocation().search;
  const result = new URLSearchParams(value);
  return result;
}

export default function ProductManagementComponent(props) {
  const query = useQuery();
  const history = useHistory();

  return (
    <div>
      {query.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: 'inherit',
            fontSize: '18px',
          }}>
          <span
            style={{
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            Product Management - Create Product
          </span>
        </Grid>
      ) : null}

      {(() => {
        const res = query.get('mode');
        switch (res) {
          case 'viewList':
            return <ProductComponent />;
          case 'create':
            return <ProductManagementForm />;
          default: {
            history.push('/products?mode=viewList');
          }
        }
      })()}
    </div>
  );
}
