import { Box } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
/* import DeleteIcon from "@material-ui/icons/Delete"; */
import EditIcon from '@material-ui/icons/Edit';
/* import VisibilityIcon from "@material-ui/icons/Visibility"; */
import React, { useEffect } from 'react';
import { PremiumFrequencyService } from '../../remote-api/api/master-services';

const premiumFrequencyService = new PremiumFrequencyService();

const freq$ = premiumFrequencyService.getPremiumFrequencies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionBlock: {
    textAlign: 'center',
    '& a:nth-child(2)': {
      marginLeft: 10,
      marginRight: 15,
    },
  },
});

export default function PremiumRuleTable({ forBenefit, setEditIndex, ruleList, handleEdit, onClickEdit }) {
  const classes = useStyles();
  const [premiumFrequncyList, setPremiumFrequncyList] = React.useState([]);
  const preventDefault = event => event.preventDefault();

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  useObservable(freq$, setPremiumFrequncyList);

  const getFrequencyName = id => {
    if (premiumFrequncyList.length === 0 || !id) return '';
    return premiumFrequncyList.find(f => f.id === id).name;
  };

  const handleEditClick = e => {
    (Object.prototype.toString.call(handleEdit) == '[object Undefined]' && handleEdit()) || preventDefault(e);
  };

  let component = null;

  if (ruleList && ruleList.length > 0) {
    component = (
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Rule Definition</TableCell>
            <TableCell align="center">Premium Amount</TableCell>
            <TableCell align="center">Payment Frequency</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ruleList?.map((rule, idx) => (
            <TableRow key={idx}>
              <TableCell align="center">{rule.ruleDefinition || rule.expression}</TableCell>
              <TableCell align="center">
                {rule?.premiumPaymentFrequencies?.map((p, id) => (
                  <div key={`amt${id}`}>{p.premiumAmount}</div>
                ))}
              </TableCell>
              <TableCell align="center">
                {rule?.premiumPaymentFrequencies?.map((p, id) => (
                  <div key={`freq${id}`}>{getFrequencyName(p.premiumPaymentFrequncyId)}</div>
                ))}
              </TableCell>
              <TableCell className={classes.actionBlock}>
                {/* <Link href="javascript:void(0)" onClick={preventDefault} >
                                        <VisibilityIcon />
                                    </Link> */}
                <Link
                  href="javascript:void(0)"
                  onClick={() => {
                    onClickEdit(idx);
                  }}>
                  <EditIcon style={{ color: '#313c96' }} />
                </Link>
                {/* <Link href="javascript:void(0)" onClick={preventDefault}>
                                        <DeleteIcon />
                                    </Link> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  } else {
    component = (
      <Box alignItems="center" pt={30} pb={30} justifyContent="center" display="flex" width="100%" height="100%">
        <span>No premiums are available</span>
      </Box>
    );
  }

  return component;
}
