/* import Grid from '@material-ui/core/Grid';
import SidebarButtons from '../../../@jumbo/components/AppLayout/partials/SideBar/SIdebarButtons';
import Divider from '@material-ui/core/Divider'; */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
/* import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'; */
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import EO2V2Widget from '../../../modules/shared-components/components/eo2v2.widget';
import ChartDialog from './chart.dialog';
import OTPDialogBox from './otp-dialog-box';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    marginLeft: 15,
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];

const STATIC_TIMELINE = [
  {
    timestamp: new Date(2021, 2, 15, 18, 35),
    title: 'Central QC Pending',
    description: 'SBIG Central to Claims Team',
  },
  {
    timestamp: new Date(2021, 2, 15, 18, 28),
    title: 'Under QC Done',
    description: 'SBIG Regional to Regional',
  },
  {
    timestamp: new Date(2021, 2, 15, 18, 18),
    title: 'Assigned to Internal team',
    description: 'SBIG Regional to SBIG Regional',
  },
  {
    timestamp: new Date(2021, 2, 15, 17, 45),
    title: 'Assigned to Regional team',
    description: 'SBIG Central to SBIG Regional',
  },
];

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

const widgetData = [
  { caption: 'P', title: 'Provider', widContent: <Pie data={data} /> },
  { caption: 'A', title: 'Agent', widContent: <Bar options={options} data={data} /> },
  { caption: 'P', title: 'Product', widContent: <Line options={options} data={data} /> },
  { caption: 'B', title: 'Bank', widContent: <Doughnut data={data} /> },
];

class SamplePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      chartDialogOpen: false,
      activeChart: {},
      widgetList: widgetData,
    };
  }

  handleClickOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  handleChartDialog = (status, widget = {}) => {
    this.setState({
      ...this.state,
      chartDialogOpen: status,
      activeChart: widget,
    });
  };

  handleCloseWidget = id => {
    const wl = [...this.state.widgetList];
    wl.splice(id, 1);
    this.setState({
      ...this.state,
      widgetList: wl,
    });
  };

  render() {
    const { widgetList, chartDialogOpen, open, activeChart } = this.state;

    return (
      <div>
        <Typography style={{ fontSize: '22px', fontWeight: 700 }} color="primary">
          Dashboard
        </Typography>
        <Grid container spacing={3}>
          {widgetList.map((widget, id) => (
            <Grid item xs={12} sm={6} md={4}>
              <EO2V2Widget
                {...widget}
                handleClose={() => this.handleCloseWidget(id)}
                doFullScreen={this.handleChartDialog}
              />
            </Grid>
          ))}
        </Grid>

        <ChartDialog handleChartDialog={this.handleChartDialog} open={chartDialogOpen} data={activeChart} />

        <OTPDialogBox handleClose={this.handleClose} open={open} />
      </div>
    );
  }
}

export default SamplePage;
