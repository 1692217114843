import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Eo2v2GlobalErrorMessageSnackbar = props => {
  const [prop, setProp] = React.useState({ open: false, message: { code: '', body: '' } });

  const handleClose = (event, reason) => {
    setProp({ open: false, message: { code: '', body: '' } });
  };

  document.addEventListener('errorHappend', event => {
    const { detail } = event || {};
    const data = detail?.response?.data || {};
    let code = data.code || 'ERR';
    let msg = data.message || 'Error occured';
    setProp({ open: true, message: { code, body: msg } });
  });

  return (
    <Snackbar
      open={prop.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert severity="error" sx={{ width: '100%' }}>
        {prop.message.code} - {prop.message.body}
      </Alert>
    </Snackbar>
  );
};
